import React from 'react';
import useStyles from './Testimonials.styles';
import Carousel from 'react-material-ui-carousel';
import { Container, Grid, Hidden } from '@material-ui/core';
import TestimonyCard from './TestimonyCard';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import testimonials from './Testimonials.data';
import birds from '../../../../images/vuelo_pajaro.png';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const Testimonials = () => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down(769));
  const rows = testimonials.reduce(function (rows, key, index) {
    return (index % 2 == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows;
  }, []);
  const classes = useStyles();
  return (
    <section id="testimonios" className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <Grid container justifyContent="center">
          <div className={classes.cardContainer}>
            <Grid item xs={12}>
              <Carousel
                NextIcon={<ArrowForwardIosIcon className={classes.nextIcon} />}
                PrevIcon={<ArrowBackIosIcon className={classes.backIcon} />}
                indicators={false}
                fullHeightHover={false}
                navButtonsAlwaysVisible={true}
                interval={8000}
                className={classes.carrouselSection}
                navButtonsProps={{
                  // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                  style: {
                    backgroundColor: 'transparent',
                    padding: 0,
                    margin: 0,
                  },
                }}
              >
                {isSmallDevice
                  ? testimonials.map((item, i) => (
                      <TestimonyCard
                        key={i}
                        name={item.name}
                        lastName={item.lastName}
                        company={item.company}
                        comment={item.comment}
                        image={item.image}
                        socialNetwork={item.socialNetwork}
                        commentUrl={item.commentUrl}
                        rol={item.rol}
                      />
                    ))
                  : rows.map(
                      (
                        row: Array<{
                          name: string;
                          lastName: string;
                          company: string | undefined;
                          comment: string;
                          image: string;
                          commentUrl: string;
                          socialNetwork: string;
                          rol: string;
                        }>,
                        i: React.Key | null | undefined
                      ) => (
                        <Grid
                          container
                          key={i}
                          style={{ paddingLeft: '70px', paddingRight: 'auto' }}
                        >
                          {row.map((col, i) => (
                            <Grid key={i} item xs={6}>
                              <TestimonyCard
                                key={i}
                                name={col.name}
                                lastName={col.lastName}
                                company={col.company}
                                image={col.image}
                                comment={col.comment}
                                commentUrl={col.commentUrl}
                                socialNetwork={col.socialNetwork}
                                rol={col.rol}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )
                    )}
              </Carousel>
            </Grid>
          </div>
        </Grid>
      </Container>
    </section>
  );
};

export default Testimonials;
