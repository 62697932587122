import React from 'react';
import { Button, Container, Grid, Hidden } from '@material-ui/core';
import useStyles from './Index.styles';
import IndexViewData from './Index.data';

const IndexView = () => {
  const { header, title, subtitle, elements } = IndexViewData;
  const { registerButton } = elements;
  const classes = useStyles();
  return (
    <React.Fragment>
      <section className={classes.root}>
        <Container id="inicio" className={classes.container} maxWidth="lg">
          <Grid item xs={12} md={6} className={classes.indexGroup}>
            <div id="empresas-tag">Empresas</div>
            <Hidden xsDown>
              <Grid item xs={8} sm={12}>
                <h1 className={classes.title}>{title}</h1>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item xs={12} sm={12}>
                <h1 className={classes.title}>
                  La forma más simple de hacer tus <strong> pagos al extranjero</strong>
                </h1>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid className={classes.transferGroup} item xs={12}>
                <Button
                  href={`${process.env.GATSBY_URL_NEW_FRONT}/forms/createCompany/basic-information`}
                  className={classes.transferButton}
                  onClick={(e) => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: 'buttonClick',
                      category: 'Empresas',
                      action: 'click_registra',
                      label: 'Index, click en registra tu empresa.',
                    });
                  }}
                >
                  {registerButton.label}
                </Button>
              </Grid>
            </Hidden>
            <Hidden xsDown>
              <Grid id="subtitle" item xs={9} sm={11}>
                <span>
                  Somos una plataforma ágil de pagos internacionales que se acomoda a las
                  necesidades de tu empresa. Transfiere con precios hasta un 65% más bajos que otras
                  alternativas y con la velocidad que necesitas.
                </span>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid id="subtitle" item xs={12} sm={12}>
                Somos una plataforma ágil de pagos internacionales que se acomoda a las necesidades
                de tu empresa. Transfiere con precios hasta un 65% más bajos que otras alternativas
                y con la velocidad que necesitas.
              </Grid>
            </Hidden>
            <Hidden xsDown>
              <Grid id="subtitleEnteprise" item sm={12}>
                <span>
                  Ya son más de <b>1.100 empresas</b> las que confían en nosotros.
                </span>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid id="subtitleEnteprise" item sm={12}>
                <span>
                  Ya son más de <b>1.100 empresas</b> las que confían en nosotros.
                </span>
              </Grid>
            </Hidden>
            <Grid id="textSumate" item md={12}>
              <span>¡Súmate!</span>
            </Grid>
            <Hidden mdUp>
              <Grid className={classes.transferGroup} item xs={12}>
                <Button
                  href={process.env.GATSBY_REGISTRA_EMPRESA_URL}
                  className={classes.transferButton}
                  onClick={(e) => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: 'buttonClick',
                      category: 'Empresas',
                      action: 'click_registra',
                      label: 'Empresas, click en registra tu empresa.',
                    });
                  }}
                >
                  {registerButton.label}
                </Button>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default IndexView;
