import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import background from '../../../../../content/assets/icons/Enterprise/EnterpriseIndex.svg';
import backgroundResponsive from '../../../../../content/assets/icons/Enterprise/EnterpriseIndexResponsive.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#fff',
      color: '#074F71',
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: `auto`,
      backgroundPosition: `170% -40%`,
      '& #empresas-tag': {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '22px ',
        margin: 0,
        marginBottom: '13px',
      },
      [theme.breakpoints.down(1500)]: {
        backgroundPosition: `170% 0%`,
      },
      [theme.breakpoints.down(1300)]: {
        backgroundPosition: `130% 30%`,
      },
      [theme.breakpoints.down(769)]: {
        backgroundImage: `url(${backgroundResponsive})`,
        backgroundPosition: `100% 70%`,
      },
    },
    container: {
      height: `auto`,
      marginTop: 72,
      paddingTop: 90,
      paddingLeft: `6%`,
      paddingRight: `6%`,
      paddingBottom: 100,
      [theme.breakpoints.up(1921)]: {
        paddingLeft: `0%`,
        paddingRight: `0%`,
      },
      [theme.breakpoints.down(500)]: {
        paddingBottom: 80,
        paddingLeft: `10%`,
        paddingRight: `10%`,
        paddingTop: 25,
      },
    },
    indexGroup: {
      height: `auto`,
      '& #subtitle': {
        fontSize: 19,
        paddingRight: '10px',
        [theme.breakpoints.down(500)]: {
          fontSize: 14,
          lineHeight: `18px`,
        },
      },
      '& #subtitleEnteprise': {
        fontSize: 22,
        marginTop: 39,
        [theme.breakpoints.down(769)]: {
          fontSize: 28,
          textAlign: `center`,
        },
        [theme.breakpoints.down(500)]: {
          fontSize: 22,
          lineHeight: `27px`,
          textAlign: `center`,
        },
      },
      '& #textSumate': {
        fontSize: 48,
        marginTop: 30,
        fontWeight: 300,
        color: `#F8B133`,
        [theme.breakpoints.down(769)]: {
          marginBottom: 22,
          textAlign: `center`,
        },
        [theme.breakpoints.down(500)]: {
          fontSize: 30,
          lineHeight: `37px`,
          fontWeight: 400,
          marginTop: 10,
        },
      },
    },
    header: {
      fontSize: 18,
      fontWeight: 'bold',
      marginTop: 0,
      marginBottom: 0,
      '& span': {
        fontWeight: 500,
      },
      lineHeight: '55px',
      [theme.breakpoints.down(500)]: {
        fontSize: 11,
        textAlign: `left`,
        lineHeight: `11px`,
        marginBottom: 12,
      },
    },
    title: {
      fontSize: 48,
      marginTop: 0,
      fontWeight: 400,
      '& span': {
        fontWeight: 500,
      },
      lineHeight: '58px',
      [theme.breakpoints.down(1364)]: {
        fontSize: 44,
      },
      [theme.breakpoints.down(769)]: {
        fontSize: 50,
      },
      [theme.breakpoints.down(500)]: {
        '& strong': {
          fontWeight: 600,
        },
        fontSize: 30,
        lineHeight: `35px`,
      },
    },
    firstSubSection: {
      marginBottom: '72px',
      '& h1': {
        margin: 0,
        marginBottom: '36px',
      },
      '& h2': {
        margin: 0,
        fontWeight: 300,
      },
    },
    transferButton: {
      background: '#FAB234',
      border: 0,
      textAlign: 'center',
      textTransform: 'inherit',
      color: '#074F71',
      borderRadius: '40px',
      fontSize: 22,
      width: 329,
      height: 50,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      '&:hover': {
        background: '#074F71',
        color: '#FAB234',
        boxShadow: '0px 2px 10px #888888',
      },
      [theme.breakpoints.down(769)]: {
        width: `100%`,
      },
      [theme.breakpoints.down(500)]: {
        fontSize: 18,
        fontWeight: 800,
      },
    },
    transferGroup: {
      height: `auto`,
      marginBottom: 50,
      [theme.breakpoints.down(769)]: {
        paddingLeft: `10%`,
        paddingRight: `10%`,
        marginBottom: 0,
      },
      [theme.breakpoints.down(500)]: {
        paddingLeft: `0%`,
        paddingRight: `0%`,
        marginBottom: 0,
      },
    },
  })
);

export default useStyles;
