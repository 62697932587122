import React from 'react';
import BannerSection from './Banner/Banner';
import IndexSection from './Index/Index';
import Advantages from './Advantages/Advantages';
import Steps from './Steps/Steps';
import Testimonials from './Testimonials/Testimonials';
import Pay from './Pay/Pay';
import Transparency from './Transparency/Transparency';

const Enterprise = () => {
  return (
    <div className="landing-page">
      <IndexSection />
      <BannerSection />
      <Testimonials />
      <Steps />
      <Advantages />
      <Pay />
      <Transparency />
    </div>
  );
};

export default Enterprise;
