import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import background from '../../../../../content/assets/icons/Transparency/Cuadricula.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#FFF',
      backgroundImage: `url(${background}), url(${background})`,
      backgroundPosition: 'left 20px, right 20px',
      backgroundRepeat: 'no-repeat',
      color: '#004F71',
      [theme.breakpoints.down(1780)]: {
        backgroundPosition: '-15% 20px, 115% 20px',
      },
      [theme.breakpoints.down(1500)]: {
        backgroundPosition: '-20% 20px, 120% 20px',
      },
      [theme.breakpoints.down(1370)]: {
        backgroundPosition: '-30% 20px, 130% 20px',
      },
    },
    container: {
      height: `100%`,
      paddingTop: 80,
      paddingLeft: `6%`,
      paddingRight: `6%`,
      paddingBottom: 100,
      [theme.breakpoints.up(1921)]: {
        paddingLeft: `0%`,
        paddingRight: `0%`,
        margin: `auto`,
      },
      [theme.breakpoints.down(501)]: {
        paddingLeft: `10%`,
        paddingRight: `10%`,
      },
    },
    title: {
      fontSize: 48,
      fontWeight: 400,
      color: '#36ADAC',
      margin: '0',
      marginBottom: 40,
      textAlign: 'center',
      [theme.breakpoints.down(1364)]: {
        fontSize: 44,
      },
      [theme.breakpoints.down(769)]: {
        fontWeight: 500,
      },
      [theme.breakpoints.down(501)]: {
        fontSize: 30,
        lineHeight: `72px`,
        textAlign: `left`,
        fontWeight: 600,
        marginBottom: 30,
      },
    },
    h2: {
      fontSize: '30px',
      fontWeight: 300,
      textAlign: 'center',
      margin: '0',
      marginBottom: '78px',
      padding: '0 50px',
    },
    gridItemsContainer: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 900,
      height: `auto`,
      position: 'relative',
      [theme.breakpoints.up(1921)]: {
        maxWidth: `90%`,
      },
      '& p': {
        fontSize: '22px',
        margin: '0',
        paddingLeft: '36px',
        [theme.breakpoints.down(769)]: {
          fontSize: 20,
          textAlign: `left`,
          lineHeight: `18px`,
        },
        [theme.breakpoints.down(501)]: {
          fontSize: '14px',
          paddingLeft: 0,
        },
      },
      '& a': {
        color: `#004F71`,
      },
      '& .MuiGrid-grid-xs-11': {
        marginBottom: 50,
      },
      '& img': {
        float: `right`,
        [theme.breakpoints.down(1280)]: {
          float: `none`,
        },
      },
    },
    [theme.breakpoints.down(1280)]: {
      root: {
        backgroundImage: 'none',
      },
      h1: {
        fontSize: '72px',
      },
      h2: {
        maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      gridItemsContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '750px',
      },
    },
    [theme.breakpoints.down(769)]: {
      gridItemsContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '600px',
        '& p': {
          fontSize: '18px',
          margin: '0',
          marginBottom: '0px',
          paddingLeft: '0px',
        },
      },
    },
    [theme.breakpoints.down(500)]: {
      h1: {
        fontSize: '36px',
        marginBottom: '21px',
      },
      h2: {
        margin: '0',
        fontSize: '18px',
        maxWidth: '280px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '46px',
        padding: '0',
      },
      gridItemsContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '360px',
        '& p': {
          fontSize: '14px',
          margin: '0',
          marginBottom: '0px',
          paddingLeft: '0px',
        },
        '& .MuiGrid-grid-xs-10:nth-child(2)': {
          marginBottom: '72px',
        },
        '& .MuiGrid-grid-xs-10:nth-child(4)': {
          marginBottom: '72px',
        },
      },
    },
    subsection: {
      float: `left`,
      paddingLeft: `10%`,
      paddingRight: `10%`,
      width: `100%`,
      height: `auto`,
      display: `block`,
      backgroundColor: `transparent`,
      [theme.breakpoints.down(1000)]: {
        padding: 0,
      },
      [theme.breakpoints.down(769)]: {
        paddingTop: 0,
      },
      '& #subsectionText': {
        margin: 0,
        width: `100%`,
        float: `left`,
        fontSize: 22,
        textAlign: `center`,
        color: `#36ADAC`,
        fontFamily: `Montserrat`,
        fontWeight: `500`,
        paddingTop: 20,
        lineHeight: `27px`,
        [theme.breakpoints.down(769)]: {
          width: `100%`,
          fontSize: 20,
          fontWeight: `600`,
        },
        [theme.breakpoints.down(501)]: {
          fontSize: 22,
        },
      },
      '& #subsectionBox': {
        width: `25%`,
        margin: 0,
        [theme.breakpoints.down(769)]: {
          width: `100%`,
        },
      },
    },
    complianceButton: {
      background: '#FFF',
      color: '#36ADAC',
      borderRadius: '40px',
      padding: '0px 25px',
      border: '2px solid #36ADAC',
      fontSize: 22,
      width: `100%`,
      height: 60,
      textTransform: `capitalize`,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      '&:hover': {
        background: '#36ADAC',
        color: `#FFF`,
        border: '2px solid #36ADAC',
      },
      [theme.breakpoints.down(1000)]: {
        fontSize: 12,
      },
      [theme.breakpoints.down(769)]: {
        width: 130,
        color: `#074F71`,
        textTransform: `capitalize`,
      },
    },
    Link: {
      fontSize: 22,
      color: `#36ADAC`,
    },
    boxIcon: {
      [theme.breakpoints.down(769)]: {
        textAlign: `center`,
        marginBottom: 20,
      },
      [theme.breakpoints.down(500)]: {
        textAlign: `left`,
        marginBottom: 15,
        paddingLeft: 45,
      },
    },
    boxText: {
      marginBottom: 50,
    },
  })
);

export default useStyles;
