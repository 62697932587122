import { Container, Grid, Hidden, Paper, Button, Divider } from '@material-ui/core';
import React from 'react';
import useStyles from './Banner.styles';

const Banner = () => {
  var items = [
    {
      message: 'Transfiere',
      messagebold1: 'dólares a todo el mundo',
      message2: 'o paga localmente en ',
      messagebold2: ' 35 monedas',
    },
    {
      message: 'Paga al',
      messagebold1: 'extranjero',
      message2: 'de forma ',
      messagebold2: 'masiva a través de nóminas',
    },
    {
      messagebold1: 'Automatiza ',
      message2: 'tus procesos de pagos a través de ',
      messagebold2: 'nuestra API',
    },
  ];

  function Item(props: any) {
    return (
      <p>
        {props.item.message} <strong>{props.item.messagebold1} </strong>
        {props.item.message2}
        <strong>{props.item.messagebold2}</strong>
      </p>
    );
  }

  const classes = useStyles();
  return (
    <section className={classes.root} id="#banner">
      <Container className={classes.container} maxWidth="lg">
        <Grid className={classes.gridContainer} container alignItems="flex-start">
          <Grid id="box1" item xs={12} sm={4} md={4}>
            <p>
              Transfiere<strong> dólares a todo el mundo</strong> o paga localmente en{' '}
              <strong>35 monedas</strong>
              <Hidden xsDown>
                <br></br>
              </Hidden>
              <Hidden smUp>
                <Divider />
              </Hidden>
            </p>
          </Grid>
          <Grid id="box2" item xs={12} sm={4} md={4}>
            <p>
              Paga al <strong>extranjero</strong> de forma{' '}
              <strong>masiva a través de nóminas</strong>{' '}
              <Hidden xsDown>
                <br></br>
              </Hidden>
              <Hidden smUp>
                <Divider />
              </Hidden>{' '}
            </p>
          </Grid>
          <Grid id="box3" item xs={12} sm={4} md={4}>
            <p>
              <strong>Automatiza</strong> tus procesos de pagos a través de{' '}
              <strong>nuestra API</strong>
            </p>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Banner;
