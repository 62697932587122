import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      color: '#074F71',
      [theme.breakpoints.down(769)]: {
        margin: '0 auto',
      },
    },
    personContainer: {
      display: 'flex',
      marginBottom: '40px',
      [theme.breakpoints.down(769)]: {
        paddingLeft: '30px',
      },
      [theme.breakpoints.down(501)]: {
        paddingLeft: '0px',
      },
    },
    avatar: {
      backgroundColor: '#128c',
      width: '182px',
      height: '182px',
      border: '2px solid #f0f0f0',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '20px',
      [theme.breakpoints.down(1365)]: {
        width: '150px',
        height: '150px',
      },
      [theme.breakpoints.down(1000)]: {
        width: '120px',
        height: '120px',
      },
      [theme.breakpoints.down(769)]: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.down(501)]: {
        width: '100px',
        height: '100px',
        marginBottom: '17px',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    title: {
      lineHeight: '32px',
      textAlign: 'center',
      fontSize: '17px',
      marginTop: '30px',
      [theme.breakpoints.down(1365)]: {
        fontSize: '18px',
      },
      [theme.breakpoints.down(1000)]: {
        fontSize: '22px',
      },
      [theme.breakpoints.down(501)]: {
        fontSize: '18px',
        textAlign: 'center',
      },
    },
    company: {
      fontSize: '22px',
      color: '#004F71',
      lineHeight: '32px',
      fontWeight: 'bold',
      textAlign: 'center',
      [theme.breakpoints.down(1000)]: {
        fontSize: '16px',
      },
      [theme.breakpoints.down(501)]: {
        fontSize: '16px',
        textAlign: 'center',
      },
    },
    comment: {
      fontSize: '18px',
      lineHeight: '22px',
      fontStyle: 'italic',
      marginBottom: '26px',
      width: '80%',
      margin: '0 auto',
      height: '180px',
      textAlign: 'justify',
      [theme.breakpoints.down(769)]: {
        fontSize: '14px',
        textAlign: 'center',
        height: 'auto',
      },
      [theme.breakpoints.down(501)]: {
        height: 'auto',
      },
    },
    socialNetwork: {},
    link: { textDecoration: 'none' },
    [theme.breakpoints.down(769)]: {
      personContainer: {
        marginBottom: '20px',
      },

      comment: {
        width: '90%',
      },
    },
    [theme.breakpoints.down(501)]: {
      comment: {
        width: '100%',
      },
    },
  })
);
export default useStyles;
