import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import background from '../../../../../content/assets/icons/Enterprise/Cuadricula.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      backgroundImage: `url(${background})`,
      backgroundPosition: 'right 220px',
      backgroundRepeat: 'no-repeat',
      color: '#004F71',
      height: `auto`,
      [theme.breakpoints.down(1580)]: {
        backgroundPosition: '115% 220px',
      },
      [theme.breakpoints.down(1315)]: {
        backgroundPosition: '130% 220px',
      },
      [theme.breakpoints.down(1200)]: {
        backgroundPosition: '140% 220px',
      },
      [theme.breakpoints.down(1140)]: {
        backgroundImage: `none`,
      },
    },
    container: {
      height: `100%`,
      paddingTop: 122,
      paddingLeft: `6%`,
      paddingRight: `6%`,
      paddingBottom: 100,
      [theme.breakpoints.up(1921)]: {
        paddingLeft: `0%`,
        paddingRight: `0%`,
        margin: `auto`,
      },
      [theme.breakpoints.down(769)]: {
        paddingTop: 80,
        paddingBottom: 60,
      },
      [theme.breakpoints.down(501)]: {
        paddingLeft: `10%`,
        paddingRight: `10%`,
      },
    },
    title: {
      fontSize: 48,
      fontWeight: 300,
      margin: '0',
      marginBottom: 72,
      textAlign: `left`,
      lineHeight: `37px`,
      [theme.breakpoints.down(769)]: {
        fontSize: 50,
        textAlign: `center`,
      },
      [theme.breakpoints.down(500)]: {
        fontSize: 30,
        padding: `0 15%`,
        textAlign: `center`,
        marginBottom: 40,
      },
    },
    gridItemsContainer: {
      marginLeft: `2%`,
      marginRight: 'auto',
      lineHeight: `27px`,
      height: `auto`,
      maxWidth: 900,
      position: 'relative',
      '& p': {
        fontSize: '22px',
        marginTop: 0,
        marginBottom: 30,
        paddingLeft: 61,
        [theme.breakpoints.down(769)]: {
          paddingLeft: 0,
          textAlign: `center`,
          marginBottom: 50,
        },
        [theme.breakpoints.down(500)]: {
          fontSize: 16,
          lineHeight: `19px`,
        },
      },
      '& img': {
        '&:first-child': {
          float: `right`,
          [theme.breakpoints.down(769)]: {
            float: `none`,
          },
        },
      },
      [theme.breakpoints.down(769)]: {
        margin: 0,
      },
    },
    boxIcon: {
      [theme.breakpoints.down(769)]: {
        textAlign: `center`,
      },
      [theme.breakpoints.down(500)]: {
        marginBottom: 15,
      },
    },
  })
);

export default useStyles;
