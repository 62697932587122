import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Enterprise from '../components/Landing/Enterprise/Enterprise';
import SEO from '../components/SEO';

const PAGE_TITLE = 'Transacciones Internacionales para Empresas';
const PAGE_DESCRIPTION =
  'Únete a las más de 1.100 empresas que usan CurrencyBird para enviar dinero a más de 170 países. Regístrate y comienza a ahorrar hasta un 65% en tarifas.';

const EnterprisePage: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title={PAGE_TITLE} description={PAGE_DESCRIPTION} />
      <Enterprise />
    </>
  );
};

export default EnterprisePage;

export const pageQuery = graphql`
  query EnterprisePage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
