import { Container, Grid } from '@material-ui/core';
import React from 'react';
import useStyles from './Advantages.styles';
import IconLaptop from '../../../../../content/assets/icons/Enterprise/EnterpriseAdvantageLaptop.svg';
import IconMoney from '../../../../../content/assets/icons/Enterprise/EnterpriseAdvantageMoneyIcon.svg';
import SheetIcon from '../../../../../content/assets/icons/Transparency/EnterpriseSheetIcon.svg';

const Technologies = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <section className={classes.root} id="tecnologías">
        <Container className={classes.container} maxWidth="lg">
          <Grid item xs={12}>
            <h2 className={classes.title}>
              Con <b>CurrencyBird</b> podrás:
            </h2>
          </Grid>
          <Grid container className={classes.gridItemsContainer}>
            <Grid className={classes.boxIcon} item xs={12} md={2}>
              <img src={SheetIcon} />
            </Grid>
            <Grid item xs={12} md={10}>
              <p>
                Realizar <strong>pagos masivos</strong> al extranjero a través de un{' '}
                <strong>sistema de nóminas en cuestión de pocos minutos</strong>
              </p>
            </Grid>
            <Grid className={classes.boxIcon} item xs={12} md={2}>
              <img src={IconLaptop} />
            </Grid>
            <Grid item xs={12} md={10}>
              <p>
                <strong>Automatizar y optimizar</strong> los pagos a través de nuestra{' '}
                <strong>API</strong>
              </p>
            </Grid>
            <Grid className={classes.boxIcon} item xs={12} md={2}>
              <img src={IconMoney} />
            </Grid>
            <Grid item xs={12} md={10}>
              <p>
                <strong>Integrar nuestra aplicación</strong> mediante tu ERP o sistema de
                contabilidad, para <strong>controlar</strong> el flujo de{' '}
                <strong>tus pagos internacionales</strong> realizados mediante nuestra plataforma
              </p>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Technologies;
