import React from 'react';
import { Avatar, Grid, Hidden } from '@material-ui/core';
import useStyles from './TestimonyCard.styles';

export interface ITestimonyData {
  name: string;
  lastName: string;
  company?: string;
  comment: string;
  socialNetwork?: string;
  image: any;
  commentUrl?: string;
  rol?: string;
}

const TestimonyCard = (props: ITestimonyData) => {
  const { name, lastName, company, comment, socialNetwork, image, commentUrl, rol } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container className={classes.personContainer}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Avatar aria-label="testimonio" src={image} className={classes.avatar} />
        </Grid>
        <div className={classes.comment}>{comment}</div>
        <Grid
          container
          style={{ maxHeight: '96px', margin: 'auto 0' }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <Hidden xsDown>
            <Grid item xs={12}>
              <div className={classes.title}>
                {name}, {rol}
              </div>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12}>
              <div className={classes.title}>
                {name}, {rol}
              </div>
            </Grid>
          </Hidden>
          {company && (
            <Grid item xs={12}>
              <a className={classes.link} href={commentUrl} target="_blank">
                <div className={classes.company}>{company}</div>
              </a>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/*socialNetwork && (
        <div className={classes.socialNetwork}>{socialNetwork}</div>
      )*/}
    </div>
  );
};

export default TestimonyCard;
