import cristobal_solminihac from '../../../../images/Testimonials/cristobal_solminihac.png';
import eliana_moreno from '../../../../images/Testimonials/eliana_moreno.png';
import enrique_mena from '../../../../images/Testimonials/enrique_mena.png';
import jose_correa from '../../../../images/Testimonials/jose_correa.png';
import juan_carlos_zegers from '../../../../images/Testimonials/juan_carlos_zegers.png';
import juan_jose_renteria from '../../../../images/Testimonials/juan_jose_renteria.png';
import autofact from '../../../../images/Testimonials/autofact.png';
import misabogados from '../../../../images/Testimonials/mis_abogados.png';
import snuuper from '../../../../images/Testimonials/snuuper.png';
import endeavor from '../../../../images/Testimonials/endeavor.png';

const testimonials: any[] = [
  {
    name: 'José Manuel Correa',
    rol: 'Director ejecutivo',
    company: 'www.endeavor.cl',
    commentUrl: 'https://www.endeavor.cl',
    image: endeavor,
    comment:
      '"¡El servicio es espectacular, nos facilita mucho la vida, somos una organización, que en nuestro core esta el apoyo a la internacionalización de nuestros emprendedores, por ende estamos constantemente usando el servicio de CurrencyBird, para pagar nuestros giros en distintos países."',
  },
  {
    name: 'Cristóbal de Solminihac',
    rol: 'CEO',
    company: 'www.autofact.cl',
    commentUrl: 'https://www.autofact.cl',
    image: autofact,
    comment:
      '"¡En Autofact Chile necesitamos enviar dinero a México, Colombia y Estados Unidos. Nos cambiamos de nuestro proveedor anterior a CurrencyBird. Definitivamente una buena decisión: mejores precios de transferencia, una plataforma fácil y un servicio rápido. ¡Gracias!”',
  },
  {
    name: 'Juan José Rentería',
    rol: 'Gerente General',
    company: 'www.misabogados.com',
    commentUrl: 'https://www.misabogados.com',
    image: misabogados,
    comment:
      '"¡Hola! Solo agradecer a CurrencyBird por el servicio bacán que están dando! En MisAbogados.com llevamos 2 años haciendo transferencias a Colombia y México a través de su sistema y siempre ha sido rápido y con tasas razonables! 🙌."',
    socialNetwork: '@cdesolminihac',
  },
  {
    name: 'Enrique Mena',
    rol: 'CEO',
    company: 'www.snuuper.com',
    commentUrl: 'https://www.snuuper.com',
    image: snuuper,
    comment:
      '"¡Excelente servicio! Los recomiendo completamente, como empresa lo hemos utilizado en varias oportunidades. Son un gran apoyo para los emprendimientos como nosotros, rápido y más económico."',
  },
  // {
  //   name: 'Juan Carlos',
  //   lastName: 'Zegers',
  //   company: 'www.andino4.com',
  //   commentUrl: 'https://www.andino4.com',
  //   image: juan_carlos_zegers,
  //   comment:
  //     '"¡Espectacular el servicio de CurrencyBird! Lo probé para enviar dólares a USA y resultó muchísimo más simple, rápido y barato. Los felicito!"',
  // },
  // {
  //   name: 'Eliana',
  //   lastName: 'Moreno',
  //   company: 'www.unibag.cl',
  //   commentUrl: 'https://www.unibag.cl',
  //   image: eliana_moreno,
  //   comment:
  //     '"¡En UNIBAG hemos utilizado CurrencyBird para pagar a nuestros proveedores en el extranjero desde hace un par de años y funciona excelente! Los recomiendo 100%. Rápido, ningún inconveniente y todo transparente!"',
  // },
];

export default testimonials;
