import { Container, Grid, Hidden } from '@material-ui/core';
import React from 'react';
import useStyles from './Transparency.styles';
import CheckIcon from '../../../../../content/assets/icons/Transparency/EnterpriseCheckIcon.svg';
import SheetIcon from '../../../../../content/assets/icons/Transparency/EnterpriseSheetIcon.svg';
import BookIcon from '../../../../../content/assets/icons/Transparency/EnterpriseBookIcon.svg';
import CheckIconResponsive from '../../../../../content/assets/icons/Transparency/EnterpriseCheckIconResponsive.svg';
import SheetIconResponsive from '../../../../../content/assets/icons/Transparency/EnterpriseSheetIconResponsive.svg';
import BookIconResponsive from '../../../../../content/assets/icons/Transparency/EnterpriseBookIconResponsive.svg';

const Transparency = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <section className={classes.root} id="transparencia">
        <Container className={classes.container} maxWidth="lg">
          <Grid container>
            <Grid item xs={12}>
              <h2 className={classes.title}>Transparencia</h2>
            </Grid>
            <Grid container className={classes.gridItemsContainer}>
              <Grid className={classes.boxIcon} item xs={12} md={1}>
                <Hidden xsDown>
                  <img src={CheckIcon} />
                </Hidden>
                <Hidden smUp>
                  <img src={CheckIconResponsive} />
                </Hidden>
              </Grid>
              <Grid className={classes.boxText} item xs={12} md={11}>
                <p>
                  En CurrencyBird seguimos todas las{' '}
                  <strong>regulaciones nacionales y las normativas internacionales</strong> que,
                  como empresa de transferencias de divisas, nos resultan aplicables.
                </p>
              </Grid>
              <Grid className={classes.boxIcon} item xs={12} md={1}>
                <Hidden xsDown>
                  <img src={SheetIcon} />
                </Hidden>
                <Hidden smUp>
                  <img src={SheetIconResponsive} />
                </Hidden>
              </Grid>
              <Grid className={classes.boxText} item xs={12} md={11}>
                <p>
                  Cumplimos con lo dispuesto en la{' '}
                  <a target="_blank" href="https://www.uaf.cl/legislacion/nuestra_ley.aspx">
                    Ley Nº19.913{' '}
                  </a>
                  y estamos debidamente registrados en la{' '}
                  <strong>
                    <a target="_blank" href="https://www.uaf.cl/">
                      Unidad de Análisis Financiero (UAF).
                    </a>
                  </strong>
                </p>
              </Grid>
              <Grid className={classes.boxIcon} item xs={12} md={1}>
                <Hidden xsDown>
                  <img src={BookIcon} />
                </Hidden>
                <Hidden smUp>
                  <img src={BookIconResponsive} />
                </Hidden>
              </Grid>
              <Grid className={classes.boxText} item xs={12} md={11}>
                <p>
                  Contamos con un <strong>modelo de Prevención de Delitos</strong>, de acuerdo a la{' '}
                  <a target="_blank" href="https://www.bcn.cl/leychile/navegar?idNorma=1008668">
                    Ley Nº 20.393
                  </a>{' '}
                  que establece la Responsabilidad Penal de las Personas Jurídicas.
                </p>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.subsection}>
              <h3 id="subsectionText">
                Conoce más sobre nuestro programa de cumplimiento
                <a className={classes.Link} href="/compliance">
                  {' '}
                  aquí
                </a>
              </h3>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Transparency;
