import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import background1 from '../../../../../content/assets/flyingBirds.png';
import background2 from '../../../../../content/assets/flyingBirdsRight.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: '#fff',
      padding: '122px 0',
      maxHeight: '670px',
    },
    cardContainer: {
      minHeight: '400px',
      maxWidth: '1134px',
    },
    nextIcon: {
      right: 0,
    },
    backIcon: {
      left: '-30px',
    },
    carrouselSection: {
      height: 'auto',
      background: '#fff !important',
      '& button': {
        margin: '0 10px',
        position: 'relative',
        backgroundColor: '#fff',
        top: 'calc(50% - 20px) !important',
        color: '#494949',
        fontSize: '32px',
        transition: '200ms',
        cursor: 'pointer',
        '&:hover': {
          background: '#fff !important',
        },
      },
      [theme.breakpoints.down(769)]: {
        padding: '0 3%',
        margin: '0 40px',
      },
      [theme.breakpoints.down(501)]: {
        padding: '0 8%',
        margin: 0,
      },
    },
    [theme.breakpoints.down(1921)]: {
      container: {
        padding: '0 6%',
      },
    },
    [theme.breakpoints.down(769)]: {
      cardContainer: {
        minHeight: '250px',
      },
      container: {
        padding: 0,
      },
    },
    [theme.breakpoints.down(501)]: {
      root: {
        padding: '80px 0',
      },
      container: {
        padding: '0 11.15%',
      },
    },
  })
);
export default useStyles;
