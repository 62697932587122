import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import background from '../../../../../content/assets/icons/Pay/Trazado.svg';
import backgroundResponsive from '../../../../../content/assets/icons/Pay/TrazadoResponsive.svg';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#FEEFD6',
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: `center`,
      color: '#004F71',
      height: `auto`,
      [theme.breakpoints.down(769)]: {
        backgroundImage: `url(${backgroundResponsive})`,
        backgroundSize: `cover`,
      },
    },
    container: {
      paddingTop: 80,
      paddingLeft: `6%`,
      paddingRight: `6%`,
      paddingBottom: 80,
      [theme.breakpoints.up(1921)]: {
        paddingLeft: `0%`,
        paddingRight: `0%`,
        margin: `auto`,
      },
      [theme.breakpoints.down(501)]: {
        paddingLeft: `10%`,
        paddingRight: `10%`,
      },
      '& .MuiGrid-grid-xs-3': {
        paddingRight: `2%`,
      },
    },
    title: {
      fontSize: 48,
      color: `#F8B133`,
      fontWeight: 500,
      margin: '0',
      marginBottom: 38,
      textAlign: `left`,
      [theme.breakpoints.down(501)]: {
        fontSize: 30,
        fontWeight: `bold`,
      },
    },
    gridItemsContainer: {
      marginLeft: `2%`,
      marginRight: 'auto',
      lineHeight: `27px`,
      height: 370,
      maxWidth: 1030,
      position: 'relative',
      [theme.breakpoints.down(769)]: {
        height: `auto`,
      },
      '& p': {
        fontSize: '22px',
        marginTop: 0,
        marginBottom: 60,
        paddingLeft: 18,
        [theme.breakpoints.down(501)]: {
          fontSize: 16,
          lineHeight: `19px`,
          marginBottom: 45,
        },
      },
      '& #paragraph1': {
        [theme.breakpoints.down(501)]: {
          paddingRight: `24%`,
        },
      },
      '& #paragraph2': {
        [theme.breakpoints.down(501)]: {
          paddingRight: `25%`,
        },
      },
      '& img': {
        '&:first-child': {
          width: '42px',
          height: '42px',
          float: `right`,
        },
      },
    },
    message: {
      fontSize: 36,
      color: `#F8B133`,
      fontWeight: `bold`,
      paddingLeft: 100,
      lineHeight: `44px`,
      marginTop: 48,
      marginBottom: 100,
      [theme.breakpoints.down(1364)]: {
        paddingLeft: `30%`,
        fontSize: 28,
      },
      [theme.breakpoints.down(769)]: {
        paddingLeft: 0,
        textAlign: `center`,
        margin: 0,
      },
      [theme.breakpoints.down(501)]: {
        fontSize: 30,
        lineHeight: `37px`,
        padding: `0 10%`,
        fontWeight: 600,
      },
    },
    transferButton: {
      background: '#FAB234',
      border: 0,
      textAlign: 'center',
      textTransform: 'inherit',
      color: '#074F71',
      borderRadius: '40px',
      fontSize: 22,
      width: 329,
      height: 50,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      '&:hover': {
        background: '#074F71',
        color: '#FAB234',
        boxShadow: '0px 2px 10px #888888',
      },
      [theme.breakpoints.down(769)]: {
        width: `100%`,
      },
      [theme.breakpoints.down(500)]: {
        fontSize: 18,
        fontWeight: 800,
      },
    },
    transferGroup: {
      paddingLeft: `0%`,
      paddingRight: `0%`,
      marginBottom: 0,
      marginTop: 40,
    },
  })
);

export default useStyles;
